import styles from "./Help.module.css";

import androidCollect from "../../assets/android-collect.png";
import iosCollect from "../../assets/ios-collect.png";

const Help = () => {
  const goDns = () => window.open("https://help.bancon.cc/mm/dns", "_blank");

  return (
    <div>
      <div className={styles.m}>
        <div className="btn_sty6">部分地区的网友反应本站遭到劫持！</div>
        <div className={styles.sec}>
          以下我们提供暂时的解决办法！给您带来不便，请谅解，也谢谢网友对我们的长期支持！
        </div>

        <div className={`${styles.grey_b}`}>
          <span className={styles.lt}>视频无法观看教程</span>
        </div>
        <div className={`${styles.light_g}`}>
          <span className={styles.dark_title}>视频无法观看教程</span>
        </div>
        <div className={`${styles.grey_b}`}>
          <div className={styles.dark_title}>
            第二种办法：请下载双子星DNS
            <span className={styles.hover} onClick={goDns}>
              【点击下载双子星DNS】
            </span>
          </div>
        </div>
        <div className={`${styles.grey_b}`}>
          <div>
            <span className={styles.dark_title}>
              第三种办法：进行下载后观看
            </span>
            <span
              onClick={() => window.open("page/help/fdm")}
              className={styles.dt}
            >
              【FDM下载教程】
            </span>
            <span
              onClick={() => window.open("page/help/thunder")}
              className={styles.dt}
            >
              【迅雷下载教程】
            </span>
          </div>
        </div>
        <div className={`${styles.grey_b}`}>
          <span className={styles.dt}>
            如何永久找到本站？15秒看完下面方法，永久访问本站！
          </span>
        </div>
        <div className={`${styles.light_g}`}>
          <div className={styles.dark_title}>
            第一种办法：如果您记不住本站域名，请收藏本站域名，本站3天会换一次域名，收藏越多越永久能访问本站。
          </div>
          <div className={styles.l10}>
            1.使用电脑的用户，请按键盘上的
            <span className={styles.dt}>Ctrl+D</span>進行收藏
          </div>
          <div className={styles.l10}>
            2.苹果手机用户请在浏览器点击
            <img height={30} width={30} src={iosCollect} />
            ，然后添加到<span className={styles.dt}>个人收藏</span>或者添加到
            <span className={styles.dt}>主屏幕</span>。
          </div>
          <div className={styles.l10}>
            3.安卓手机用户请点击或者<span className={styles.dt}>打开设置</span>
            ，然后点击 <img height={30} width={30} src={androidCollect} />
            <span className={styles.dt}>添加到书签</span>或者
            <span className={styles.dt}>添加到主屏幕</span>。
          </div>
        </div>
        <div className={`${styles.grey_b}`}>
          <div className={styles.dark_title}>
            第二种办法：请下载双子星DNS
            <span className={styles.hover} onClick={goDns}>
              【点击下载双子星DNS】
            </span>
          </div>
          <div>
            打开双子星DNS，输入本站海外域名：
            <span
              className={styles.dt}
              onClick={() => window.open("www.maomiav.com")}
            >
              www.maomiav.com
            </span>{" "}
            即可正常观看
          </div>
        </div>
        <div className={`${styles.grey_b}`}>
          <div className={`${styles.dt} ${styles.f12_b}`}>
            注：
            部分地区部分运营商会暂时或长久屏蔽拦截我们的播放线路，给你带来不便，请谅解，我们正在解决此问题！
          </div>
          <div className={`${styles.dt} ${styles.f12_b}`}>
            特别提醒：我们永久提倡保护未成年人，若你未满18周岁，请立即关闭离开！
          </div>
        </div>
      </div>
    </div>
  );
};
export default Help;
