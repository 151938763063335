import { createContext, useState, useEffect } from "react";
import useRequest from "../hooks/useRequest";
import { ELocalKey, PROD_SITE } from "../utils/constant";
import { useContext } from "react";
import {
  documentInsertStatisticsScript,
  documentWriteStatisticsScript,
  encrypt,
} from "../utils/utils";

export const ConfigBaseContext = createContext({
  configBaseList: {},
  // getDefaultData: () => {},
});

export const ConfigBaseProvider = ({ children }) => {
  const [configBaseList, setConfigBaseList] = useState({});
  const { makeRquest: requestConfigBase } = useRequest("config/base", "post");

  useEffect(() => {
    const fetchConfigBase = async () => {
      try {
        const { data } = await requestConfigBase({ site: PROD_SITE });

        if (data) {
          setConfigBaseList(data);
          localStorage.setItem(ELocalKey._BASE, encrypt(JSON.stringify(data)));
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchConfigBase();
  }, []);

  useEffect(() => {
    if (configBaseList && configBaseList?.yimi_statistic_domain) {
      documentWriteStatisticsScript(configBaseList?.yimi_statistic_domain);
      // documentInsertStatisticsScript(configBaseList?.yimi_statistic_domain);
    }
  }, [configBaseList]);

  const value = { configBaseList };
  return (
    <ConfigBaseContext.Provider value={value}>
      {children}
    </ConfigBaseContext.Provider>
  );
};

export const useConfigBase = () => {
  return useContext(ConfigBaseContext);
};
