import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";

import useRequest from "../../hooks/useRequest";

import {
  fetchData,
  getGB,
  randomNumberInRange,
  secondsTimeFormat,
  toFmt,
} from "../../utils/utils";

import Image from "../../components/image/Image";
import Post from "../../components/post/Post";
import BackButton from "../../components/backButton/BackButton";
import Wrapper from "../../components/wrapper/Wrapper";

import styles from "./Detail.module.css";
import { CONTAIN_DISPLAY } from "../../utils/constant";
import { useHeader } from "../../contexts/header.context";
import { useConfigBase } from "../../contexts/configBase.context";

const Detail = () => {
  const navigate = useNavigate();
  const { configBaseList } = useConfigBase();
  const { setIsMobileHeaderChildOpen } = useHeader();
  const [ciliDetail, setCiliDetail] = useState([]);
  const [ciliRandomList, setCiliRandomList] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageListDecrypt, setImageListDecrypt] = useState([]);

  const { makeRquest: requestDetail } = useRequest("cili/detail", "post");
  const { makeRquest: requestRandom } = useRequest("cili/random", "post");

  let [searchParams] = useSearchParams();

  const notify = () => toast.success("您已成功复制下载地址～");

  const fetchDetail = useCallback(async () => {
    try {
      const { data } = await requestDetail({
        id: searchParams.get("id"),
      });
      const content = data.info.content || [];
      const imageConcatContent = [data.info.cover].concat(content);

      setImageList(imageConcatContent);
      setCiliDetail(data.info || {});
    } catch (err) {
      console.log(err);
    }
  }, [searchParams]);

  const fetchRandom = useCallback(async () => {
    try {
      const {
        data: { random },
      } = await requestRandom({
        name: searchParams.get("name") || "",
      });

      const randomNum = randomNumberInRange(0, 9);
      const isArr = random[0] instanceof Array;
      if (isArr) {
        return setCiliRandomList(random[randomNum]);
      }
      setCiliRandomList(random);
    } catch (err) {
      console.log(err);
    }
  }, [searchParams]);

  const fmtImageList = () => {
    let newFormatList = [];

    imageList &&
      imageList.forEach(async (item, index) => {
        const imageUrlKey = configBaseList.image_url || "";
        const encryptUrls = `${imageUrlKey}/${item}.txt`;

        const res = await fetchData(encryptUrls);

        newFormatList[index] = res;
      });

    setImageListDecrypt(newFormatList);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handlePostOnClick = (postValue) => {
    const id = (postValue && postValue.id) || 1;
    const name = searchParams.get("name") || "";
    navigate(`/cili/detail?id=${id}&name=${name}`);
  };

  useEffect(() => {
    fetchDetail();
    window.scrollTo(0, 0);
  }, [fetchDetail, searchParams]);

  useEffect(() => {
    fetchRandom();
  }, [fetchRandom, searchParams]);

  useEffect(() => {
    setIsMobileHeaderChildOpen(false);
  }, []);

  useEffect(() => {
    fmtImageList();
  }, [configBaseList, imageList]);

  return (
    <Wrapper>
      <div className={styles.detailBodyContainer}>
        <BackButton />
        <div className={styles.detailContent}>
          <div className={styles.detailActorContainer}>
            <div className={styles.detailActor}>
              <div className={styles.actorProfile}>
                <Image
                  srcValue={ciliDetail && ciliDetail.cover}
                  className={styles.iCvr}
                />
                <Image
                  srcValue={ciliDetail && ciliDetail.cover}
                  className={styles.actorProfileImg}
                />
              </div>
              <div className={styles.detailActorDesc}>
                <p className={styles.title}>
                  {(ciliDetail && ciliDetail.title) || "-"}
                </p>
                <div className={styles.desc}>
                  <p className={styles.descText}>
                    标签：{(ciliDetail && ciliDetail.tags) || "-"}
                  </p>
                  <p className={styles.descText}>
                    时长：
                    {secondsTimeFormat(ciliDetail && ciliDetail.duration) ||
                      "-"}
                  </p>
                  <p className={styles.descText}>
                    发行日期：
                    {(ciliDetail && toFmt(ciliDetail.update_time * 1000)) ||
                      "-"}
                  </p>
                  <p className={styles.descText}>
                    制片商：{(ciliDetail && ciliDetail.maker) || "-"}
                  </p>
                  <p className={styles.descText}>
                    系列：{(ciliDetail && ciliDetail.series) || "-"}
                  </p>
                  <p className={styles.descText}>
                    类别：{(ciliDetail && ciliDetail.sub_tag) || "-"}
                  </p>
                  <p className={styles.descText}>
                    演员：{(ciliDetail && ciliDetail.actors) || "-"}
                  </p>
                </div>
                <div className={styles.labelViews}>
                  {/* <div className={styles.label}>
                  <img src={pic} alt="pic" />
                  <p>12P</p>
                </div> */}
                  {/* <div className={styles.views}>
                  <img src={eye} alt="eye" />
                  <p>12P</p>
                </div> */}
                </div>
              </div>
            </div>
            <div className={styles.detailDownloadContainer}>
              {ciliDetail &&
                ciliDetail.down_url &&
                ciliDetail.down_url.map((val, index) => {
                  const sizeIsString = isNaN(val.size);

                  return (
                    <div className={styles.detailDownload} key={index}>
                      <div className={styles.downloadAddress}>
                        <p className={styles.downloadAddressTitle}>下载地址</p>
                        <p className={styles.downloadAddressText}>
                          {val.is_hd === 1 && (
                            <span className={styles.downloadHD}>HD</span>
                          )}
                          <a href={val.down_url}>{val.down_url}</a>
                        </p>
                      </div>
                      <div className={styles.downloadButton}>
                        {/* <button className={styles.downloadButtonSecondary}>
                复制地址
              </button> */}
                        <div className={styles.downloadDetail}>
                          <p>
                            文件大小：
                            <span className={styles.docSize}>
                              {sizeIsString
                                ? (val.size || "").toUpperCase()
                                : getGB(val.size) || "-"}
                            </span>
                          </p>
                          <p>
                            分享日期：
                            <span className={styles.docShareDate}>
                              {toFmt(ciliDetail.update_time * 1000) || "-"}
                            </span>
                          </p>
                        </div>
                        <button
                          className={styles.downloadButtonPrimary}
                          onClick={() => {
                            copy(val.down_url);
                            notify();
                          }}
                        >
                          复制地址
                        </button>
                        <Toaster />
                      </div>
                    </div>
                  );
                })}
            </div>
            <p className={styles.magnetLinkMethod}>
              复制磁力链接方法：
              <br /> 1、右键磁力名称选择【复制链接地址】
              <br />
              2、点击磁力名称后面的【复制】 PS：下载工具可选择：比特彗星
              比特精灵 uTorrent QBittorrent 迅雷（请您自行搜索下载）
            </p>
          </div>
          <div className={styles.previewImageContainer}>
            <div className={styles.previewImageTitle}>影片预览</div>
            <div className={styles.picDetailPreviewImage}>
              {imageList &&
                imageList.map((src, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => openImageViewer(index)}
                      className={styles.picDetailPreviewImageContainer}
                    >
                      <Image srcValue={src} className={styles.iCvr} />
                      <Image
                        srcValue={src}
                        className={styles.picDetailPreviewImage}
                      />
                    </div>
                  );
                })}
              {isViewerOpen && (
                <ImageViewer
                  src={imageListDecrypt}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  disableScroll={true}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 100,
                  }}
                  className={styles.ImageViewer}
                  closeOnClickOutside={true}
                />
              )}
            </div>
          </div>
          <div className={styles.detailRecommend}>
            <div className={styles.recommendTitle}>猜你喜欢</div>
            <div className={styles.detailRecommendPostList}>
              {ciliRandomList &&
                ciliRandomList.map((val) => (
                  <Post
                    post={val}
                    key={val.id}
                    // vertical={VERTICAL_DISPLAY.includes(searchParams.get("name"))}
                    contain={CONTAIN_DISPLAY.includes(searchParams.get("name"))}
                    onClick={handlePostOnClick}
                  ></Post>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Detail;
