import React, { useState, useEffect } from "react";

import styles from "./FloatButton.module.css";
import Image from "../image/Image";

import arrowUp from "../../assets/arrow-up.png";
import share from "../../assets/share_4.png";
import copy from 'copy-to-clipboard';

const list = () => [
  {
    img: <img src={arrowUp} alt="arrow-up" width={25} height={25}></img>,
    hide: () => window.scrollY === 0,
    event: () => {
      return window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  {
    img: <img src={share} alt="share" width={25} height={25}></img>,
    event: () => {
      let text = `${window.location.href} 提示：请复制域名去手机浏览器中打开，请勿在微信和QQ中打开，因为包含成人内容。尽量使用宙斯浏览器，谷歌浏览器，火狐浏览器，或者苹果安卓系统自带浏览器访问～`;
      copy(text);
    },
  },
];

const FloatButton = () => {
  const [isScroll, setIsScroll] = useState(0);
  const onScroll = () => setIsScroll(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className={styles.floatButton}>
      {list()
        .filter((x) => (x.hide ? !x.hide() : true))
        .map((val, index) => {
          return (
            <div key={index}  className={styles.floatButtonCard} onClick={val.event}>
              {val.img}
            </div>
          );
        })}
    </div>
  );
};

export default FloatButton;
