import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import Enter from "./views/enter/Enter";
import Home from "./views/home/Home";
import Detail from "./views/detail/Detail";
import Help from "./views/help/Help";

const Terms = React.lazy(() => import("././views/information/Terms"));
const Privacy = React.lazy(() => import("./views/information/Privacy"));
const DMCA = React.lazy(() => import("./views/information/DMCA"));
const Info2257 = React.lazy(() => import("./views/information/2257"));

const DSA = React.lazy(() => import("./views/information/DSA"));
const TrustSafety = React.lazy(() => import("./views/information/TrustSafety"));
const ParentalControl = React.lazy(() =>
  import("./views/information/ParentalControl")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Enter />,
  },
  {
    path: "/main",
    element: <Home />,
  },
  {
    path: "/cili/detail",
    element: <Detail />,
  },
  {
    path: "/information/terms",
    element: <Terms />,
  },
  {
    path: "/information/privacy",
    element: <Privacy />,
  },
  {
    path: "/information/dmca",
    element: <DMCA />,
  },
  {
    path: "/information/2257",
    element: <Info2257 />,
  },
  {
    path: "/information/dsa",
    element: <DSA />,
  },
  {
    path: "/information/trust-safety",
    element: <TrustSafety />,
  },
  {
    path: "/information/parental-control",
    element: <ParentalControl />,
  },
  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
