import React from "react";

import Image from "../image/Image";
import { fmtDateSince, getGB, toFmt } from "../../utils/utils";

import styles from "./Post.module.css";

const Post = ({ post, vertical = false, contain = false, onClick = () => {} }) => {
  const handlerOnClick = () => {
    onClick(post);
  };

  return (
    <div className={styles.postCard} onClick={handlerOnClick}>
      {/* {vertical === true ? (
        <div className={styles.postProfileVertical}>
          <Image
            srcValue={post && post["thumb"]}
            className={styles.iCvr}
            size={true}
            alt="meinv img"
          />
          <Image
            srcValue={post && post["thumb"]}
            className={styles.postImageVertical}
            size={true}
            alt="meinv img"
          />
        </div> */}
         {contain === true ? (
          <div className={styles.postProfileVertical}>
            <Image
              srcValue={post && post["thumb"]}
              className={styles.iCvr}
              size={true}
              alt="meinv img"
            />
            <Image
              srcValue={post && post["thumb"]}
              className={styles.postImageVertical}
              size={true}
              alt="meinv img"
            />
          </div>
      ) : (
        <div className={styles.postProfile}>
          <Image
            srcValue={post && post["thumb"]}
            className={styles.iCvr}
            size={true}
            alt="meinv img"
          />
          <Image
            srcValue={post && post["thumb"]}
            className={styles.postImage}
            size={true}
            alt="meinv img"
          />
        </div>
      )}

      <div className={styles.postDesc}>
        <p className={styles.postDescTitle}>
          {post.title && post.title.length > 30
            ? `${post.title.slice(0, 30)}...`
            : post.title}
        </p>
        <div className={styles.postViews}>
          <div className={styles.postViewLabel}>
            {/* <p> {toFmt(post.update_time * 1000) || "-"}</p> */}
            <p> {fmtDateSince(post.update_time * 1000) || "-"}</p>
          </div>
          <div className={styles.postViewCheck}>
            {/* <img src={check} alt="profcheckile" /> */}
            <p>
              {isNaN(post?.down_url && post?.down_url[0]?.size)
                ? post?.down_url && (post?.down_url[0]?.size || "").toUpperCase()
                : getGB(post?.down_url && post?.down_url[0]?.size)}
            </p>
            {/* <p>{post.views}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
