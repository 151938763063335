import { createContext, useCallback, useContext, useEffect, useState } from "react";
import useRequest from "../hooks/useRequest";
import { ELocalKey } from "../utils/constant";
import { clearExpireLocalStorage, decrypt, encrypt } from "../utils/utils";

export const CategoryContext = createContext({
  categories: [],
});

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const { makeRquest: requestCategories } = useRequest(
    "cili/categories",
    "post"
  );

  const fetchCategories = useCallback(async () => {
   try{
    const res = await requestCategories();

    if (res?.data?.categories) {
      const __v = Object.values(res?.data?.categories);
      setCategories(__v);
      localStorage.setItem(ELocalKey._CATER, encrypt(JSON.stringify(__v)));
    }
   }catch(err){
    console.log(err)
   }
  },[requestCategories]);

  useEffect(() => {
    clearExpireLocalStorage();
    try {
      const data = localStorage.getItem(ELocalKey._CATER);
      const val = JSON.parse(decrypt(data));
      setCategories(val);
    } catch {
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    setCategorySelected(categories[0]);
  }, [categories]);

  const value = { categories, setCategorySelected, categorySelected };
  return (
    <CategoryContext.Provider value={value}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => {
  return useContext(CategoryContext);
};
