import { createContext, useState } from "react";
import { useContext } from "react";

export const HeaderContext = createContext({
  isMobileHeaderChildOpen: false,
  setIsMobileHeaderChildOpen: () => {},
});

export const HeaderProvider = ({ children }) => {
  const [isMobileHeaderChildOpen, setIsMobileHeaderChildOpen] = useState(false);

  const value = { isMobileHeaderChildOpen, setIsMobileHeaderChildOpen };
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};
