import axios from "axios";
import {
  base64Sign,
  encrypt,
  decrypt,
  isMobile,
  getLocalData,
} from "../utils/utils";
import { ELocalKey, PROD_SITE } from "../utils/constant";
import { useState } from "react";

// create an axios instance
// axios.create({
//   baseURL: "http://172.247.9.210:8900/api/", // url = base url + request url
//   withCredentials: true, // send cookies when cross-domain requests
//   timeout: 5000, // request timeout
// });

// axios.defaults.headers.common["suffix"] = "kRy1zN";
axios.defaults.headers.post['Content-Type'] = 'text/plain';

axios.interceptors.request.use(
  (config) => {
    const val = getLocalData(ELocalKey._BASE)?.config?.json_host || "https://utt.51jiajiao.top/";
    if (!val) return config;

    let _url = `/${config?.__endpoint}`;
    
    if (_url && _url.indexOf("config/base") >= 0) {
      config.url = `${val}data${_url}-${PROD_SITE}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("cili/categories") >= 0) {
      config.url = `${val}data/site_cili${_url}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("cili/banner") >= 0) {
      config.url = `${val}data/site_cili${_url}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("cili/list") >= 0) {
      const reqData = JSON.parse(decrypt(config.data['post-data']) || { name: "", page: 1});
      const order = 1;
      config.url = `${val}data/site_cili${_url}-${reqData.name || ''}-${order}-${reqData.page || 1}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("cili/detail") >= 0) {
      const reqData = JSON.parse(decrypt(config.data['post-data']) || { id: ""});
      config.url = `${val}data/site_cili${_url}-${reqData.id || ''}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("cili/random") >= 0) {
      const reqData = JSON.parse(decrypt(config.data['post-data']) || { name: ""});
      config.url = `${val}data/site_cili${_url}-${reqData.name}.js`;
      config.method = "get";
    }

    if (config.url && config.url.indexOf("172.247.9.210:8900") >= 0) {
      config.headers['suffix'] = "kRy1zN";
      config.headers["Content-Type"] = "application/json"
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const useRequest = (url, method, noEncrypt = false) => {
  const [error, setError] = useState("");

  let baseUrl = "http://172.247.9.210:8900/api"; //"https://site11api.beladns.com/api"

  const makeRquest = async (data = {}) => {
    let payload = {};

    payload["system"] = isMobile() ? 2 : 1;
    payload["timestamp"] = new Date().getTime();
    payload = { ...payload, ...data };

    if (!noEncrypt) {
      payload["encode_sign"] = base64Sign(payload);
      payload = { "post-data": encrypt(JSON.stringify(payload)) };
    }

    try {
      const response = await axios.request({
        url: `${baseUrl}/${url}`,
        data: payload,
        method,
        __data: payload,
        __endpoint: url,
      });
      let __data = decrypt(response.data.data, response.data.suffix);
      __data = JSON.parse(__data);
      response.data.data = __data;

      return response.data;
    } catch (err) {
      setError(err.message);
    }
  };

  return { makeRquest, error };
};

export default useRequest;
