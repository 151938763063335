import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../../contexts/category.context";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination as SwiperPagination,
} from "swiper/modules";

import Post from "../../components/post/Post";
import Image from "../../components/image/Image";
import Pagination from "../../components/pagination/Pagination";
import Wrapper from "../../components/wrapper/Wrapper";

import styles from "./Home.module.css";
import useRequest from "../../hooks/useRequest";
import {
  clearExpireLocalStorage,
  decrypt,
  encrypt,
  isMobile,
} from "../../utils/utils";
import { ELocalKey, CONTAIN_DISPLAY } from "../../utils/constant";

import notFound from "../../assets/no-found.png";
import { useHeader } from "../../contexts/header.context";

const Home = () => {
  const navigate = useNavigate();
  const { setIsMobileHeaderChildOpen } = useHeader();
  const { categories, categorySelected, setCategorySelected } = useCategory();
  const { makeRquest: requestList } = useRequest("cili/list", "post");
  const { makeRquest: requestBanner } = useRequest("cili/banner", "post");

  const [active, setActive] = useState(1);
  const [ciliList, setCiliList] = useState([]);
  const [banners, setBanners] = useState([]);

  const handleCategoryClick = (category) => {
    setActive(1);
    setCategorySelected(category);
  };

  const handlePostOnClick = (postValue) => {
    const id = (postValue && postValue.id) || 1;
    const name = (categorySelected && categorySelected.jump_name) || "";
    navigate(`/cili/detail?id=${id}&name=${name}`);
  };

  const fetchBanner = useCallback(async () => {
    try {
      const { data } = await requestBanner();

      if (data?.banners) {
        const __v = Object.values(data?.banners);
        setBanners(__v);
        localStorage.setItem(ELocalKey._BANNER, encrypt(JSON.stringify(__v)));
      }
    } catch (err) {
      console.log(err);
    }
  }, [requestBanner]);

  const fetchList = useCallback(
    async (page = 1) => {
      try {
        const {
          data: { list },
        } = await requestList({
          // order: 1,
          page: page || 1,
          name: (categorySelected && categorySelected["jump_name"]) || "",
        });
        setCiliList(list || {});
      } catch (err) {
        console.log(err);
      }
    },

    [categorySelected]
  );

  const activeHandler = (clickedActive) => {
    const num = parseInt(clickedActive);
    setActive(num);
    fetchList(num);
  };

  useEffect(() => {
    setCiliList([]);
    if (categorySelected !== null && categorySelected !== undefined) {
      fetchList();
    }
  }, [categorySelected, fetchList]);

  useEffect(() => {
    clearExpireLocalStorage();
    try {
      const bannersData = localStorage.getItem(ELocalKey._BANNER);
      const bannersVal = JSON.parse(decrypt(bannersData));

      setBanners(bannersVal);
    } catch {
      fetchBanner();
    }
  }, []);

  useEffect(() => {
    setIsMobileHeaderChildOpen(false);
  }, [categorySelected]);

  return (
    <Wrapper>
      <div className={styles.homeBodyContainer}>
        {/* <div className={styles.homeDropdownOption}>
      <Dropdown title="时间排序" list={timeData} onClick={handleTimeClick} />
      <Dropdown title="观看次数" list={viewData} onClick={handleViewsClick} />
      <Dropdown title="HD" list={hdData} onClick={handlehdClick} />
    </div> */}
        <div className={styles.bannersContainer}>
          <Swiper
            className={styles.swipeNav}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView={isMobile() ? 1 : 2}
            spaceBetween={15}
            pagination={{
              clickable: true,
            }}
            mousewheel={{
              releaseOnEdges: true,
              forceToAxis: true,
            }}
            freeMode={true}
            modules={[Navigation, SwiperPagination, Autoplay]}
          >
            {banners.map((item, index) => {
              const hostname = window.location.origin;
              let domainLink = "/";

              if (item.url === "main") {
                domainLink = hostname.replace("download.", "");
              }

              if (item.url === "pic") {
                domainLink = hostname.replace("download.", "pic.");
              }
              return (
                <SwiperSlide
                  key={index}
                  onClick={() => window.open(domainLink, "_blank")}
                >
                  <Image
                    key={index}
                    srcValue={item && item.img}
                    className={styles.bannersImg}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={styles.homeOptionList}>
          {categories.map((category, index) => {
            return (
              <Image
                key={index}
                srcValue={category && category.icon}
                className={styles.postImage}
                onClick={() => handleCategoryClick(category)}
              />
            );
          })}
        </div>
        <div className={styles.homePostsTitle}>
          {(categorySelected && categorySelected.name) || ""}
        </div>
        <div className={styles.homePosts}>
          <div className={styles.homePostsContainer}>
            {ciliList &&
              ciliList.data &&
              ciliList.data.map((val) => (
                <Post
                  post={val}
                  key={val.id}
                  onClick={handlePostOnClick}
                  // vertical={VERTICAL_DISPLAY.includes(
                  //   categorySelected.jump_name
                  // )}
                  contain={CONTAIN_DISPLAY.includes(categorySelected.jump_name)}
                ></Post>
              ))}
          </div>
          {ciliList && ciliList.length === 0 && (
            <div className={styles.notFound}>
              <img src={notFound} alt="not-found" />
            </div>
          )}
        </div>
        <Pagination
          active={active}
          size={ciliList?.last_page}
          total={ciliList?.total}
          step={isMobile() ? 1 : 3}
          onClickHandler={activeHandler}
        />
      </div>
    </Wrapper>
  );
};

export default Home;
