export const PROD_SITE = 1;

// export const VERTICAL_DISPLAY = ['dgym','dgwm','xfmr'];
export const CONTAIN_DISPLAY = ['dgym','dgwm','xfmr', 'sjll'];


export const ELocalKey = {
    _BASE: "_BASECILI",
    _CATER: "_CATERCILI",
    _BANNER: "_BANNERCILI"
    // _CONF: "_CONF",
    // _USER: "_USER",
    // _BROWSE: "_BROWSER",
    // _TESE: "_TESE",
    // _FORMAT: "_FORMAT",
    // _MM: "_MM",
    // _CACHE: "_CACHE",
  };